
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

nav {
  img {
    width: 34px;
  }
}

.login-input {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type=number] {
    -moz-appearance: textfield;
  }
}

.loadingBlock {
  width: 220px;
  height: 220px;
  padding: 20px;
  margin: 0 auto;

  div {
    width: 100%;
    height: 100%;
    padding: 10px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      filter: invert(100%);
    }
  }
}

.rowForImages {
  text-align: center;
  width: 100%;

  .imageRow {
    display: inline-block;
    position: relative;
    width: 33%;
    float: left;
    padding: 7px;
  }
}

.smallImage {
  max-height: 100px !important;
  max-width: 100px !important;
  object-fit: contain;
}

.list-group-item {
  > a {
    color: #00ad3c;
  }
  &:hover {
    background: #00ad3c;
    > a {
      color: white;
    }
    transition: background-color 1s;
  }
}

.err {
  color: #d15656;
}

.borderBlock {
  border: dotted 1px #282c34;
  padding: 10px;
  border-radius: 10px;
}
